import { AnyAction, Store } from "redux";
import * as io from "socket.io-client";
import { GlobalState } from "./types/stateTypes";

export class ReduxSocketIoSynchronisationService {
  private _socketClient: SocketIOClient.Socket;

  constructor(store: Store<GlobalState, any>) {
    this._socketClient = io.connect();
    this._socketClient.on("server-action", (action: AnyAction) => store.dispatch(action));
  }

  onStoreAction(action: AnyAction) {
    // Don't sync if the action originated remotely
    if (!action.remote) {
      console.log(`emitting action:`, JSON.stringify(action));
      this._socketClient.emit("client-action", action);
    }
  }
}
