// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

import { createStore, applyMiddleware } from "redux";

import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";
import { ReduxStoreActionEmitter } from "./ReduxStoreActionEmitter";
import { ReduxSocketIoSynchronisationService } from "./ReduxSocketIoSynchronisationService";
import { GlobalState } from "./types/stateTypes";

export default function configureStore(initialState: Partial<GlobalState> = {}) {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const reduxStoreActionEmitter = new ReduxStoreActionEmitter();
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, reduxStoreActionEmitter.createReduxMiddleware())
  );

  const reduxSocketIoSynchronisationService = new ReduxSocketIoSynchronisationService(store);
  reduxStoreActionEmitter.subscribe((action) => reduxSocketIoSynchronisationService.onStoreAction(action));

  return store;
}
