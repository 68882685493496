import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Dropdown, ResponsiveEmbed, Row } from "react-bootstrap";
import { connect } from "react-redux";
import LiftingTable, { ColumnType } from "../components/lifting/LiftingTable";
import { getEntriesInFlight, getEntriesOnPlatform, getFlightsOnPlatform, getLiftingOrder } from "../logic/liftingOrder";
import { Entry, Flight, Language } from "../types/dataTypes";
import { GlobalState, LiftingState, MeetState } from "../types/stateTypes";

interface Props {
  lifting: LiftingState;
  meet: MeetState;
  language: Language;
  entries: readonly Entry[];
}

interface InternalState {
  chosenFlight?: Flight;
}

class LiveResultsOverlayContainer extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      chosenFlight: undefined,
    };
  }

  render() {
    const flightToDisplay = this.state.chosenFlight || this.props.lifting.flight;

    const entriesInFlight = getEntriesInFlight(
      this.props.lifting.day,
      this.props.lifting.platform,
      flightToDisplay,
      this.props.entries
    );

    const flightsOnPlatform = getFlightsOnPlatform(
      getEntriesOnPlatform(this.props.lifting.day, this.props.lifting.platform, this.props.entries)
    );
    const now = getLiftingOrder(entriesInFlight, this.props.lifting);

    return (
      <div className="container">
        <Row>
          <h1 style={{ paddingRight: "10px" }}>
            {this.props.meet.name}{" "}
            <a href="https://www.youtube.com/watch?v=j44s9P8RNs8">
              <FontAwesomeIcon icon={faYoutube} style={{ color: "red" }} />
            </a>
          </h1>
          <Dropdown style={{ padding: "5px" }}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              {this.state.chosenFlight ? `Flight ${this.state.chosenFlight}` : `Flight ${flightToDisplay}`}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {flightsOnPlatform.map((flight, i) => {
                return (
                  <Dropdown.Item key={i} onClick={() => this.setState({ chosenFlight: flight })}>
                    Flight {flight}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Row>
        <Row>
          <LiftingTable
            attemptOneIndexed={now.attemptOneIndexed}
            currentEntryId={now.currentEntryId}
            isPrimaryTable={false}
            lift={this.props.lifting.lift}
            orderedEntries={now.orderedEntries}
            resultsMode={true}
            tableId="LiveResultsOverlayTable"
            readonly={true}
            hiddenColumns={new Set<ColumnType>(["Lot"])}
          />
        </Row>
        <Row style={{ paddingTop: "10px" }}>
          <ResponsiveEmbed aspectRatio="16by9">
            <iframe
              className="embed-responsive-item"
              src="https://www.youtube.com/embed/j44s9P8RNs8"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </ResponsiveEmbed>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): Props => {
  return {
    lifting: state.lifting,
    meet: state.meet,
    entries: state.registration.entries,
    language: state.language,
  };
};
export default connect(mapStateToProps)(LiveResultsOverlayContainer);
