import React from "react";
import { ReactReduxContext } from "react-redux";
import { Store } from "redux";
import { GlobalState } from "../types/stateTypes";
interface MyProps<TComponent extends React.Component<TProps>, TProps> {
  Component: new (props: TProps) => TComponent;
  stateMapper: (state: GlobalState) => TProps;
  delayIntervalMs: number;
}

class DelayedConnectedComponent<TComponent extends React.Component<TProps>, TProps> extends React.Component<
  MyProps<TComponent, TProps>
> {
  static contextType = ReactReduxContext;
  unsubscribe: (() => void) | undefined;

  constructor(props: MyProps<TComponent, TProps>) {
    super(props);
  }

  // TODO: This method is deprecated, should probably figure out how to do this another way.
  // The suggested replacement, using constructor(), doesn't work as this.context is undefined there.
  componentWillMount() {
    this.unsubscribe = this.getStore().subscribe(() => this.onStoreUpdate());
  }

  // TODO: This is un-optimized. A proper implementation would check to see if anything relevant has changed, and if so would not re-render.
  onStoreUpdate() {
    setTimeout(() => this.forceUpdate(), this.props.delayIntervalMs);
  }

  render() {
    const props = this.props.stateMapper(this.getStore().getState());
    return <this.props.Component {...props} />;
  }

  componentWillUnmount() {
    if (this.unsubscribe !== undefined) {
      this.unsubscribe();
    }
  }

  getStore(): Store<GlobalState> {
    return this.context.store as Store<GlobalState>;
  }
}

export function connectWithDelay<TComponent extends React.Component<TProps>, TProps>(
  delayIntervalMs: number,
  comp: new (props: TProps) => TComponent,
  stateMapper: (state: GlobalState) => TProps
) {
  return function delayedConnectedComponentFactory() {
    return (
      <DelayedConnectedComponent
        delayIntervalMs={delayIntervalMs}
        Component={comp}
        stateMapper={stateMapper}
      ></DelayedConnectedComponent>
    );
  };
}
