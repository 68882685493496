import React from "react";
import { GlobalState, RecordsState, LiftingState, MeetState } from "../../types/stateTypes";
import { connect } from "react-redux";
import { getUpdatedRecordState, getRecordTypeForEntry } from "../../logic/records/records";
import LiftingTable from "../lifting/LiftingTable";
import { getEntriesInFlight, getLiftingOrder } from "../../logic/liftingOrder";
import { Entry, RecordKeyComponents, Language, RecordLift } from "../../types/dataTypes";
import { getWeightClassForEntry } from "../../logic/entry";
import { calculateRecordKey } from "../../reducers/recordsReducer";
import { localizeSexPlural, localizeEquipment, localizeRecordLift } from "../../logic/strings";

import styles from "./CommentaryView.module.scss";
import { CardDeck, Card } from "react-bootstrap";

interface StateProps {
  lifting: LiftingState;
  updatedRecordState: RecordsState;
  meet: MeetState;
  language: Language;
  entriesInFlight: Array<Entry>;
}

class CommentaryView extends React.Component<StateProps> {
  render() {
    const now = getLiftingOrder(this.props.entriesInFlight, this.props.lifting);
    const entry = now.orderedEntries.find((e) => e.id === now.currentEntryId);
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <CardDeck style={{ display: "flex", flexDirection: "column", margin: "5px", maxWidth: "500px" }}>
            <Card style={{ marginBottom: "10px" }}>
              <Card.Header className={styles.CardHeader}> {entry && entry.name} - Notes</Card.Header>
              <Card.Body style={{ fontSize: "18px" }}>{this.renderNotes(entry)}</Card.Body>
            </Card>
            <Card>
              <Card.Header className={styles.CardHeader}>Record Info</Card.Header>
              <Card.Body style={{ fontSize: "18px" }}>{this.renderRecordSections(entry)}</Card.Body>
            </Card>
          </CardDeck>

          <div style={{ width: "100%" }}>
            <LiftingTable
              attemptOneIndexed={now.attemptOneIndexed}
              currentEntryId={now.currentEntryId}
              isPrimaryTable={false}
              lift={this.props.lifting.lift}
              orderedEntries={now.orderedEntries}
              resultsMode={true}
              tableId="CommentaryTable"
              readonly={true}
            />
          </div>
        </div>

        {this.renderOplIframe(entry)}
      </div>
    );
  }

  renderNotes(entry: Entry | undefined) {
    if (entry) {
      const lines = entry.notes
        .split("\n")
        .map((line) => line.trim())
        .filter((line) => line.length > 1);
      return <p style={{ whiteSpace: "pre-wrap" }}>{lines.join("\n")}</p>;
    }
  }

  renderRecordSections(entry: Entry | undefined) {
    if (entry) {
      const liftRecord = (
        <RecordInfoThing
          entry={entry}
          weightClassesKgMen={this.props.meet.weightClassesKgMen}
          weightClassesKgWomen={this.props.meet.weightClassesKgWomen}
          weightClassesKgMx={this.props.meet.weightClassesKgMx}
          updatedRecordState={this.props.updatedRecordState}
          language={this.props.language}
          recordLift={this.props.lifting.lift}
        />
      );

      let totalRecord: JSX.Element | null = null;
      if (this.props.lifting.lift === "D") {
        totalRecord = (
          <RecordInfoThing
            entry={entry}
            weightClassesKgMen={this.props.meet.weightClassesKgMen}
            weightClassesKgWomen={this.props.meet.weightClassesKgWomen}
            weightClassesKgMx={this.props.meet.weightClassesKgMx}
            updatedRecordState={this.props.updatedRecordState}
            language={this.props.language}
            recordLift={"Total"}
          />
        );
      }

      return (
        <div>
          {liftRecord}
          {totalRecord}
        </div>
      );
    }
  }
  renderOplIframe(entry: Entry | undefined) {
    if (entry) {
      // hacky attempt to convert name into username. Likely doesn't handle many names
      const oplUsername = entry.name
        .normalize("NFD")
        .replace(/[^0-9a-z]/gi, "")
        .toLowerCase();

      return <iframe className={styles.OplIframe} src={`https://www.openpowerlifting.org/u/${oplUsername}`}></iframe>;
    }
  }
}

interface RecordThingProps {
  entry: Entry;
  weightClassesKgMen: readonly number[];
  weightClassesKgWomen: readonly number[];
  weightClassesKgMx: readonly number[];
  language: Language;
  recordLift: RecordLift;
  updatedRecordState: RecordsState;
}

class RecordInfoThing extends React.Component<RecordThingProps> {
  render() {
    const entry = this.props.entry;
    const weightClass = getWeightClassForEntry(
      entry,
      this.props.weightClassesKgMen,
      this.props.weightClassesKgWomen,
      this.props.weightClassesKgMx,
      this.props.language
    );

    const recordKeyComponents: RecordKeyComponents = {
      division: entry.divisions[0],
      equipment: entry.equipment,
      recordLift: this.props.recordLift,
      recordType: getRecordTypeForEntry(entry),
      sex: entry.sex,
      weightClass: weightClass,
    };

    const recordKey = calculateRecordKey(recordKeyComponents);
    const recordInfo = this.props.updatedRecordState.confirmedRecords[recordKey];

    return (
      <div>
        {localizeSexPlural(entry.sex, this.props.language)} {localizeEquipment(entry.equipment, this.props.language)}{" "}
        {entry.divisions[0]} {weightClass} {localizeRecordLift(this.props.recordLift, this.props.language)}
        {recordInfo === undefined ? (
          <p>Not currently set</p>
        ) : (
          <p>
            <span style={{ fontWeight: "bold" }}>{recordInfo.weight}kg</span> held by{" "}
            <span style={{ fontWeight: "bold" }}>{recordInfo.fullName}</span> set on{" "}
            <span style={{ fontWeight: "bold" }}>{recordInfo.date}</span> at{" "}
            <span style={{ fontWeight: "bold" }}>{recordInfo.location}</span>
          </p>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  const entriesInFlight = getEntriesInFlight(
    state.lifting.day,
    state.lifting.platform,
    state.lifting.flight,
    state.registration.entries
  );

  return {
    lifting: state.lifting,
    meet: state.meet,
    entriesInFlight,
    language: state.language,
    updatedRecordState: getUpdatedRecordState(state.records, state.meet, state.registration, state.language),
  };
};

export default connect(mapStateToProps)(CommentaryView);
