import React from "react";
import { connect } from "react-redux";
import LiftingTable from "../components/lifting/LiftingTable";
import { getEntriesInFlight, getLiftingOrder } from "../logic/liftingOrder";
import { Entry, Language } from "../types/dataTypes";
import { GlobalState, LiftingState, MeetState } from "../types/stateTypes";

interface StateProps {
  lifting: LiftingState;
  meet: MeetState;
  language: Language;
  entriesInFlight: Array<Entry>;
}

class StreamResultsOverlayContainer extends React.Component<StateProps> {
  render() {
    const now = getLiftingOrder(this.props.entriesInFlight, this.props.lifting);
    return (
      <LiftingTable
        attemptOneIndexed={now.attemptOneIndexed}
        currentEntryId={now.currentEntryId}
        isPrimaryTable={false}
        lift={this.props.lifting.lift}
        orderedEntries={now.orderedEntries}
        resultsMode={true}
        tableId="StreamResultsLiftingTable"
        readonly={true}
      />
    );
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  const entriesInFlight = getEntriesInFlight(
    state.lifting.day,
    state.lifting.platform,
    state.lifting.flight,
    state.registration.entries
  );

  return {
    lifting: state.lifting,
    meet: state.meet,
    entriesInFlight,
    language: state.language,
  };
};
export default connect(mapStateToProps)(StreamResultsOverlayContainer);
