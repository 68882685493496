import { connect } from "react-redux";
import { GlobalState, LiftingState, RegistrationState, MeetState } from "../../types/stateTypes";
import React from "react";
import { Entry, Lift } from "../../types/dataTypes";
import { getEntriesInFlight, getLiftingOrder } from "../../logic/liftingOrder";

import styles from "./UpNextOverlayView.module.scss";
import { liftToAttemptFieldName } from "../../logic/entry";

interface StateProps {
  lifting: LiftingState;
  registration: RegistrationState;
  meet: MeetState;
  entriesInFlight: Array<Entry>;
}

function hasInputWeight(entry: Entry, lift: Lift, attemptOneIndexed: number): boolean {
  const fieldKg = liftToAttemptFieldName(lift);
  const lifts = entry[fieldKg];
  return lifts[attemptOneIndexed - 1] !== 0;
}

class UpNextOverlay extends React.Component<StateProps> {
  render() {
    const now = getLiftingOrder(this.props.entriesInFlight, this.props.lifting);
    if (now.currentEntryId === null) {
      return null;
    }

    const currentLifterIndex = now.orderedEntries.findIndex((entry) => entry.id === now.currentEntryId);
    let rollingOrderedEntries = now.orderedEntries.slice(currentLifterIndex + 1, now.orderedEntries.length);
    if (now.attemptOneIndexed < 3) {
      // If we are on the final attempt of the lift, don't loop around to the start.
      // Technically for our use case this should show the next flight. Something to think about
      rollingOrderedEntries = rollingOrderedEntries.concat(now.orderedEntries.slice(0, currentLifterIndex));
    }

    // Remove lifters which have not input an attempt or are not lifting this event
    const filteredOrderedEntries = rollingOrderedEntries.filter((entry) => {
      const index = now.orderedEntries.findIndex((e) => e.id === entry.id);
      // Consider their next attempt if they've already taken this one
      const attemptToConsiderOneIndexed =
        index >= currentLifterIndex ? now.attemptOneIndexed : now.attemptOneIndexed + 1;

      return (
        hasInputWeight(entry, this.props.lifting.lift, attemptToConsiderOneIndexed) &&
        entry.events.some((event) => event.indexOf(this.props.lifting.lift) !== -1)
      );
    });

    return (
      <div className={styles.Container}>
        <table className={styles.Table}>
          <thead>
            <tr>
              <th className={styles.TableHeader}>
                <span className={styles.RowContent}>Next Up</span>
              </th>
            </tr>
          </thead>
          <tbody>{filteredOrderedEntries.slice(0, 5).map((entry, index) => this.renderRow(entry, index))}</tbody>
        </table>
      </div>
    );
  }

  renderRow(entry: Entry, index: number) {
    const isEven = index % 2 == 0;
    const rowStyle = isEven ? styles.RowEven : styles.RowOdd;
    return (
      <tr key={index} className={rowStyle}>
        <td>
          <span className={styles.RowContent}>{entry.name}</span>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  const entriesInFlight = getEntriesInFlight(
    state.lifting.day,
    state.lifting.platform,
    state.lifting.flight,
    state.registration.entries
  );

  return {
    lifting: state.lifting,
    registration: state.registration,
    meet: state.meet,
    entriesInFlight,
  };
};

export default connect(mapStateToProps)(UpNextOverlay);
