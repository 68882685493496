// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

// The parent component of the Lifting page, contained by the LiftingContainer.
//
// The LiftingTable, LiftingFooter, etc. all share calculated state.
// This class performs the state calculations and communicates that to its
// sub-components via props.

import React from "react";
import { connect } from "react-redux";

import LeftCard from "./LeftCard";
import LiftingFooter from "./LiftingFooter";
import LiftingHeader from "./LiftingHeader";
import LiftingTable from "./LiftingTable";
import WeighinsView from "../weighins/WeighinsView";

import styles from "./LiftingView.module.scss";

import {
  getEntriesInFlight,
  getEntriesOnPlatform,
  getFlightsOnPlatform,
  getLiftingOrder,
} from "../../logic/liftingOrder";

import { Entry, Flight, Language, Lift } from "../../types/dataTypes";
import { GlobalState, MeetState, LiftingState } from "../../types/stateTypes";
import { getFinalTotalKg, liftToStatusFieldName } from "../../logic/entry";

interface StateProps {
  meet: MeetState;
  lifting: LiftingState;
  flightsOnPlatform: Array<Flight>;
  nextFlight: Flight | null;
  entriesInNextFlight: Array<Entry> | null;
  entriesInFlight: Array<Entry>;
  language: Language;
}

type Props = StateProps;

interface InternalState {
  // If true, the LiftingTable is replaced with the Weighins page.
  // This lets the score table change arbitrary rack height and attempt information
  // without removing the current lifter or bar load displays.
  replaceTableWithWeighins: boolean;
  // display all the lifts & their results in the table, and hide the left card
  resultsView: boolean;
}

// Returns the next lift. If the current lift is deadlift, there is no next so just return deadlift
const tryGetNextLift = (lift: Lift): Lift => {
  switch (lift) {
    case "S":
      return "B";
    case "B":
      return "D";
    case "D":
      return "D";
  }
};

class LiftingView extends React.Component<Props, InternalState> {
  constructor(props: Props) {
    super(props);
    this.toggleReplaceTableWithWeighins = this.toggleReplaceTableWithWeighins.bind(this);
    this.toggleResultsview = this.toggleResultsview.bind(this);
    this.state = {
      replaceTableWithWeighins: false,
      resultsView: false,
    };
  }

  toggleReplaceTableWithWeighins = (): void => {
    this.setState({
      replaceTableWithWeighins: !this.state.replaceTableWithWeighins,
    });
  };

  toggleResultsview = (): void => {
    this.setState({
      resultsView: !this.state.resultsView,
    });
  };

  tryGetNextFlightRegion = (): JSX.Element | null => {
    if (!this.props.nextFlight || !this.props.entriesInNextFlight) {
      return null;
    }

    const fieldStatus = liftToStatusFieldName(this.props.lifting.lift);
    let nextLift = this.props.lifting.lift;
    // If we have lifters in the next flight, we should check if they're doing the current lift or the next type
    if (this.props.entriesInNextFlight.length) {
      const firstEntry = this.props.entriesInNextFlight[0];
      // If the first person in the next flight has already had an attempt at the current lift, try move them on to the next lift type.
      if (firstEntry[fieldStatus][0] !== 0) {
        nextLift = tryGetNextLift(this.props.lifting.lift);
      }
    }

    const fakeState: LiftingState = {
      day: this.props.lifting.day,
      platform: this.props.lifting.platform,
      flight: this.props.nextFlight,
      lift: nextLift,
      nextFlight: null,
      overrideAttempt: null,
      overrideEntryId: null,
    };
    const now = getLiftingOrder(this.props.entriesInNextFlight, fakeState);
    return (
      <div>
        <br />
        <h3>Next Flight - {this.props.nextFlight}</h3>
        <LiftingTable
          attemptOneIndexed={now.attemptOneIndexed}
          orderedEntries={now.orderedEntries}
          currentEntryId={null}
          isPrimaryTable={false}
          lift={nextLift}
          tableId="Next-Flight-Table"
          resultsMode={false}
          readonly={false}
        />
      </div>
    );
  };

  render() {
    const now = getLiftingOrder(this.props.entriesInFlight, this.props.lifting);

    // CPA Hack - In the results view, order by total
    if (this.state.resultsView) {
      now.orderedEntries.sort((a, b) => getFinalTotalKg(a) - getFinalTotalKg(b));
    }

    let rightElement = null;
    if (this.state.replaceTableWithWeighins === false) {
      rightElement = (
        <div>
          <LiftingTable
            attemptOneIndexed={now.attemptOneIndexed}
            orderedEntries={now.orderedEntries}
            currentEntryId={now.currentEntryId}
            isPrimaryTable={true}
            lift={this.props.lifting.lift}
            tableId={"Main-Lifting-Table"}
            readonly={false}
            resultsMode={this.state.resultsView}
          />
          {this.tryGetNextFlightRegion()}
        </div>
      );
    } else {
      rightElement = (
        <WeighinsView day={this.props.lifting.day} platform={this.props.lifting.platform} inLiftingPage={true} />
      );
    }

    return (
      <div>
        <div id="liftingView" className={styles.liftingView}>
          <LiftingHeader
            attemptOneIndexed={now.attemptOneIndexed}
            orderedEntries={now.orderedEntries}
            currentEntryId={now.currentEntryId}
          />

          <div className={styles.middleParentContainer}>
            {/* Render the left card if not in the results view */}
            {!this.state.resultsView ? (
              <div className={styles.leftCardContainer}>
                <LeftCard
                  attemptOneIndexed={now.attemptOneIndexed}
                  orderedEntries={now.orderedEntries}
                  currentEntryId={now.currentEntryId}
                  nextEntryId={now.nextEntryId}
                  nextAttemptOneIndexed={now.nextAttemptOneIndexed}
                />
              </div>
            ) : undefined}

            {/* Make the lifting table bigger if in results mode */}
            <div className={styles.rightCardContainer} style={this.state.resultsView ? { width: "100%" } : {}}>
              {rightElement}
            </div>
          </div>

          <LiftingFooter
            attemptOneIndexed={now.attemptOneIndexed}
            orderedEntries={now.orderedEntries}
            currentEntryId={now.currentEntryId}
            flightsOnPlatform={this.props.flightsOnPlatform}
            toggleReplaceTableWithWeighins={this.toggleReplaceTableWithWeighins}
            toggleResultsView={this.toggleResultsview}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: GlobalState): StateProps => {
  const day = state.lifting.day;
  const platform = state.lifting.platform;
  const flight = state.lifting.flight;
  const nextFlight = state.lifting.nextFlight;

  // Only receive entries that are in the currently-lifting group.
  const entriesInFlight = getEntriesInFlight(day, platform, flight, state.registration.entries);
  const entriesOnPlatform = getEntriesOnPlatform(day, platform, state.registration.entries);
  const entriesInNextFlight =
    nextFlight !== null ? getEntriesInFlight(day, platform, nextFlight, state.registration.entries) : null;

  // Determine available flights from the entries themselves.
  const flightsOnPlatform = getFlightsOnPlatform(entriesOnPlatform);

  return {
    meet: state.meet,
    lifting: state.lifting,
    flightsOnPlatform,
    nextFlight,
    entriesInNextFlight,
    entriesInFlight,
    language: state.language,
  };
};

export default connect(mapStateToProps)(LiftingView);
